import { useEffect, useState } from 'react';
import {Link} from "react-router-dom";

import Faqs from './faqs.tsx';

import Letters from '../../images/letters.png';
import Gettickets from '../../images/getticket.png';
import BuyCoffee from '../../images/coffee.png';



import { register } from 'swiper/element/bundle';

import "animate.css/animate.min.css";
import { AnimationOnScroll } from 'react-animation-on-scroll';

register();

function Fifth () {
  const [creator, setCreator] = useState('thah_emiola');

  return (
    <>
    <div className="p-4 pt-10 lg:pt-16 mt-10 lg:mt-18" id=''>
        <div className='flex flex-col justify-between items-center max-w-6xl m-auto'>
            <div className='flex flex-col items-start'>
                <div className='flex flex-col mb-9'>
                    <AnimationOnScroll animateIn="slideInRight" duration="0.5"><h1 className='text-[#121B00] pb-0 text-4xl lg:text-5xl ml-0 font-black text-center lg:text-center mb-4'><span className='text-[#BDC2B1]'> WE'RE NOT LINKTREE,</span> <br className='' />WE'RE MORE, FOR THE AFRICAN CREATOR!</h1></AnimationOnScroll>
                </div>
                <Link to='/register' className='bg-[#121B00] flex m-auto mb-16 text-white text-sm hover:text-black hover:bg-white hover:border-2 hover:border-[#121B00] px-11 py-3.5 rounded-full'>Get started - It's free</Link>
            </div>

            <div className='flex flex-col lg:flex-row items-center lg:justify-between'>
              <AnimationOnScroll animateIn="fadeIn" animateOut="fadeOut" duration="1" className='p-11 mb-10 lg:mr-5 bg-[#F7F9F4] flex flex-col rounded-4xl justify-between items-center box-border'>
                  <div className='flex flex-col items-center lg:items-start'>
                    <img src={Letters} />
                    <p className='mt-10 text-sm font-semibold text-center lg:text-start'>Customize your page to fit your brand style: build instant recognition</p>
                  </div>
              </AnimationOnScroll>
              <AnimationOnScroll animateIn="fadeIn" animateOut="fadeOut" duration="1" className='p-11 mb-10 lg:mr-5 bg-[#F7F9F4] flex flex-col rounded-4xl justify-between items-center box-border'>
                  <div className='flex flex-col items-center lg:items-start'>
                    <img src={Gettickets} />
                    <p className='mt-10 text-sm font-semibold text-center lg:text-start'>Manage your events, from ticket sales, to detail of attendees</p>
                  </div>
              </AnimationOnScroll>
              <AnimationOnScroll animateIn="fadeIn" animateOut="fadeOut" duration="1" className='p-11 mb-10  bg-[#F7F9F4] flex flex-col rounded-4xl justify-between items-center box-border'>
                  <div className='flex flex-col items-center lg:items-start'>
                    <img src={BuyCoffee} />
                    <p className='mt-10 text-sm font-semibold text-center lg:text-start'>Now your audience can support you, and your content they so much love</p>
                  </div>
              </AnimationOnScroll>
            </div>
            
        </div>
      
    </div>

    <div className="p-4 py-16 lg:py-24 mt-12 lg:mt-16 bg-[#F7F9F4]">
        <div className='flex flex-col justify-between items-center max-w-6xl m-auto'>
            <div className='flex flex-col items-start'>
                <div className='flex flex-col mb-9'>
                    <AnimationOnScroll animateIn="slideInRight" duration="0.5"><h1 className='text-[#121B00] pb-0 text-4xl lg:text-5xl ml-0 font-black text-center lg:text-start mb-4'>STILL <br className='lg:hidden' />WONDERING?</h1></AnimationOnScroll>
                </div>
            </div>

            <AnimationOnScroll animateIn="fadeIn" animateOut="fadeOut" duration="1" className='flex w-full flex-col max-w-2xl'>
                <Faqs />
            </AnimationOnScroll>
        </div>
      
    </div>
    </>   
  );
}

export default Fifth;
